import styled from "styled-components";

export const CheckoutStyle = styled.div``;

export const Side = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  width: 50%;
  max-width: 470px;

  @media (max-width: 960px) {
    width: 100%;
    max-width: 620px;
  }
`;

export const LeftSide = styled(Side)``;

export const RightSide = styled(Side)`
  padding-top: 32px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 36px;
  justify-content: space-between;
  align-items: flex-start;
`;

export const RowPrice = styled(Row)`
  max-width: max-content;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 16px;
`;

export const Title = styled.div`
  color: #36454f;
  text-align: center;
  font-family: Work Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
`;

export const Subtitle = styled.div`
  color: #36454f;
  font-family: Work Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  div {
    color: #36454f;

    font-family: Work Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  text-align: left;
  max-width: 254px;
  ${(props) =>
    props.maintenance &&
    `max-width: 100%; width: 100%; div {margin-bottom: 8px;}`}
`;

export const NumberHolder = styled.div`
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
  border: 1px solid #cfe2ff;
  background: #fff;
  width: 60px;
  height: 40px;
  flex-shrink: 0;
  display: flex;

  padding: 4px;

  ${(props) => props.gray && ` border: 1px solid #dae5f4;`}
  ${(props) => props.gray && ` cursor: not-allowed;`}
     ${(props) =>
    props.gray &&
    `background: #f5f7f9; color:#3b3b3b; border: 1px solid #d0deeb;`}
  svg {
    path {
      transition: all 0.5s ease-in-out;

      ${(props) => props.gray && `fill: #dae5f4;`}
    }
    ${(props) => props.gray && `cursor: not-allowed !important;`}
  }
`;

export const Number = styled.input`
  all: unset;
  max-width: 26px;
  color: #36454f;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NumberArrows = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    min-width: 24px;
    scale: 1.2;
    stroke-width: 0.1px;
    stroke: #4871cf;
    :hover {
      cursor: pointer;
    }
  }
`;

export const Price = styled.div`
  color: #36454f;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 8px;
`;

export const FeatureRow = styled.div`
  display: flex;
  gap: 14px;
  justify-content: flex-start;
  div {
    color: #36454f;
    font-family: Work Sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 123.529% */
  }
`;

export const FeatureColumn = styled(Column)`
  gap: 21px;
  padding-left: 14px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #cfe2ff;
`;

export const CheckBox = styled.div`
  border-radius: 2px;
  border: 2px solid #4871cf;
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  ${(props) => props.gray && ` border: 2px solid #dae5f4;`}
  ${(props) => props.gray && ` cursor: not-allowed;`}
     ${(props) =>
    props.gray &&
    `background: #f5f7f9; color:#3b3b3b; border: 1px solid #d0deeb;`}
`;

export const AddOnText = styled.div`
  color: #36454f;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const AddOnPrice = styled.div`
  color: #36454f;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
`;

export const AddOnDescription = styled.div`
  color: #36454f;
  font-family: Work Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  max-width: 254px;
`;

export const AddOnRow = styled(Row)`
  justify-content: flex-start;
  gap: 11px;
`;

export const AddOnColumn = styled(Column)`
  gap: 8px;
`;

export const Total = styled.div`
  color: #36454f;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  span {
    color: #36454f;
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const Vat = styled.div`
  color: #36454f;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
`;

export const Taxes = styled.div`
  line-height: 8px;
  font-size: 12px;
  color: #a4a2a2;
  font-weight: 400;
`;

export const TotalVat = styled.div`
  color: #36454f;
  font-family: Work Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 120% */
  text-transform: uppercase;

  span {
    color: #36454f;
    font-family: Work Sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }
`;

export const TotalPrice = styled.div`
  color: #36454f;
  font-family: Work Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
`;

export const Perks = styled.div`
  min-width: 150px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 5px;
  padding: 8px;

  background: rgba(72, 113, 207, 0.1);
  width: 33.333%;
  @media (max-width: 420px) {
    width: 100%;
  }
  div {
    color: #000;
    text-align: center;
    font-family: Work Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const PerksRow = styled.div`
  display: flex;
  gap: 10px;

  width: 100%;
  align-items: center;
  @media (max-width: 800px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (max-width: 420px) {
    flex-direction: column;
  }
`;
