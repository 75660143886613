import { navigate } from "gatsby";
import React, { Component, MouseEvent } from "react";
import styled, { keyframes } from "styled-components";

const Green = styled.section`
  // Styles omitted for brevity
`;

const Holder = styled.div`
  width: 100%;
  height: max-content;
`;
const Button = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 94%;
  padding: 10px;
  font-weight: 600;
  font-size: 20px;
  color: #ffff;
  border: 1px solid rgb(0, 202, 66);
  background-color: #41c93f;

  border-radius: 5px;
  :hover {
    cursor: pointer;
    color: white;
    background-color: #48df45;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Styled component for the loader
const Loader = styled.div`
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid white; /* White */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${spin} 1s linear infinite;
  margin-left: 10px;
`;

class PayButton extends Component {
  pay = (e) => {
    this.props.testit(e);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "buttonClick",
      category: "Reached checkout 3.step",
      action: "Clicked Pay Button",
      label: "Clicked on Pay Button - opened FastSpring Popup",
    });
  };

  StyledComponent = this.props.StyledComponent || Button;

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.paymentReceived = (data) => {
        console.log("Payment info:", data);
        localStorage.setItem("Purchase", JSON.stringify(data));

        const queryString = window.location.search; // This gets the entire query string

        if (data !== null) {
          const purchasedUrl = `/purchased${queryString}`; // Append the entire query string

          navigate(purchasedUrl, { state: { purchase: data } });
        }
      };
    }
  }

  render() {
    return (
      <Holder>
        <Green>
          <this.StyledComponent
            id="pay-button"
            // Props and attributes omitted for brevity
            onClick={this.pay}
          >
            {this.props.name}
            {this.props.loading && <Loader />}
          </this.StyledComponent>
        </Green>
      </Holder>
    );
  }
}

export default PayButton;
