import styled, { keyframes } from "styled-components";
import Checkmark from "./images/check.png";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  min-height: 900px;
  align-items: flex-start;
  background-color: #f5f7f9;
  box-sizing: border-box;

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: center;

    height: auto;
  }

  @media (min-width: 959px) {
    padding-top: 96px;
  }
`;
export const LeftColumn = styled.div`
  box-sizing: border-box;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* background-color: #ececee; */
  width: 50%;
  height: 80vh;
  min-height: 900px;
  z-index: 10;

  padding-bottom: 176px;
  @media (max-width: 960px) {
    padding-right: 0;
    justify-content: center;

    order: 2;
    height: max-content;
    width: 100%;
  }

  @media (max-width: 450px) {
    padding: 0;
    margin-bottom: 48px;
  }
`;

export const RightColumn = styled.div`
  box-sizing: border-box;

  display: flex;
  align-items: flex-start;

  width: 100%;
  padding-left: 8%;
  /* padding-left: 72px; */

  min-height: 900px;
  margin-bottom: 50px;
  margin-right: 8%;

  @media (max-width: 960px) {
    margin: 0;
    padding-left: 0;
    justify-content: center;
    align-items: center;
    min-height: 0;
    height: max-content;
    width: 90%;
    margin-top: 48px;
    margin-bottom: 48px;
  }
`;

export const RightColumn3 = styled(RightColumn)`
  padding-top: 20px;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: #deeefa;

  max-width: 500px;
  @media (max-width: 960px) {
    max-width: 100%;

    margin-left: 0;
    width: 90%;
  }
  @media (max-width: 400px) {
    width: 100%;
  }

  #fsc-embedded-checkout-container {
    /* transition: all 0.7s ease-in-out; */
    position: relative;
    opacity: 0;
    max-height: 0px;
    width: 110%;
    /* transform: translate3D(-5%, -108px, 0) !important;
    transform: translate3d(-5%, -29px, 0px) !important; */
    /* translate: -5% -54px; */
    ${(props) => props?.loaded && `opacity: 1; max-height: 542px;`}
    /* ${(props) => props?.loaded && `opacity: 1; max-height: 400px;`} */

    iframe {
      position: absolute !important;
      top: -64px !important;
      left: -5%;
    }

    @media (max-width: 1150px) {
      ${(props) => props?.loaded && `opacity: 1; max-height: 550px;`}
    }

    @media (max-width: 960px) {
      width: auto;
      /* transform: translate3D(0, -108px, 0); */
      ${(props) => props?.loaded && `opacity: 1; max-height: 520px;`}
      iframe {
        top: -64px !important;

        left: 0;
      }
    }

    @media (max-width: 800px) {
      width: 110%;
      iframe {
        left: -5%;
      }
      /* transform: translate3D(-5%, -108px, 0); */
    }

    overflow: hidden;
    z-index: 0 !important;
    iframe {
      z-index: 0 !important;
    }
    @media (max-width: 700px) {
      ${(props) => props?.loaded && `max-height: 525px;`}
    }
    @media (max-width: 640px) {
      ${(props) => props?.loaded && `max-height: 560px;`}
    }

    ${(props) => props?.updated && `transform: translate3D(-2.5%, -50px, 0);`}
    iframe {
      ${(props) => props?.updated && `display: none;`}
    }
  }
`;
export const UpperFormHolder = styled.div`
  @media (max-width: 500px) {
    padding: 16px 21px;
  }
`;

export const FormSecurities = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 28px 21px;
  box-sizing: border-box;
  max-width: 584px;
  width: 100%;
  @media (max-width: 960px) {
    max-width: 100%;
    padding: 32px 42px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 16px;
  }

  @media (max-width: 400px) {
    padding: 16px 21px;
  }
`;

export const FormSecurity = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  width: 30%;
  svg {
    min-width: max-content;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const FormSecurityText = styled.div`
  color: #000;
  font-family: Work Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Form = styled.form`
  box-sizing: border-box;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.07);
  padding: 32px 42px;

  max-width: 500px;
  max-height: 780px;
  @media (max-width: 960px) {
    max-width: 100%;
    width: 100%;
    max-height: 760px;
  }

  @media (max-width: 700px) {
    max-height: 780px;
  }

  @media (max-width: 640px) {
    max-height: 800px;
  }
  @media (max-width: 450px) {
    margin: 0;

    width: 100%;
  }
  @media (max-width: 500px) {
    padding: 0;
  }
`;

export const FormOld = styled(Form)`
  max-height: 100%;
`;

export const FormTitle = styled.div`
  color: #36454f;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 14px;
  span {
    font-weight: 400;
  }
`;

export const Modify = styled.div`
  font-family: Work Sans;

  position: relative;
  font-size: 14px;
  line-height: normal;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #0d9bff;
  z-index: 200;

  cursor: pointer;
  font-weight: 500;
`;
export const Logout = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
  color: #000;

  span {
    cursor: pointer;
    color: #0d9bff;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    text-decoration-line: underline;
  }
  margin-bottom: 64px;
`;

export const GoBack = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 187.5% */
  color: #8c8c8c;
  cursor: pointer;
  transform: translateY(-8px);

  span {
    color: #0d9bff;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    text-decoration-line: underline;
  }
`;

export const Input = styled.input`
  all: unset;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid ${(props) => (props.empty ? "red" : "#d0deeb")};
  height: 50px;
  width: 100%;
  margin-bottom: 10px;
  padding: 8px 16px;
  box-sizing: border-box;

  color: #000;

  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
  ${(props) =>
    props.disabled &&
    `background: #f5f7f9; color:#3b3b3b; border: 1px solid #d0deeb;`}
`;

export const FieldHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const PaymentInfo = styled.div`
  color: #000;
  font-family: Work Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  margin-bottom: 8px;
`;
export const PaymentButton = styled.section`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  border-radius: 5px;
  background: #0d9bff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-top: auto;
  @media (max-width: 400px) {
    width: 90%;
    margin: auto;
    margin-bottom: 32px;
  }
`;

export const InputLabel = styled.div`
  color: #000;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 307.143% */
`;

export const Row = styled.div`
  display: flex;
  gap: 8px;
`;

export const CheckBoxHolder = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 12px;
  input[type="checkbox"] {
    display: none;
    margin: 0;
  }

  /* Create a custom box for our checkbox */
  input[type="checkbox"] + label::before {
    margin: 0;
    margin-right: 8px;
    content: "";
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 2px solid #d9d9d9;
  }

  /* Style for when the checkbox is checked */
  input[type="checkbox"]:checked + label::before {
    margin: 0;
    margin-right: 8px;
    background: #0d9bff;
    border: 2px solid #0d9bff;

    background-image: url(${Checkmark});
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: 25%;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border-radius: 4px;

    color: white; /* adjust as needed */
  }

  label {
    color: #36454f;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    display: flex;
    align-items: center;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #d0deeb;
  margin-bottom: 35px;
  margin-top: 35px;
  position: relative;
  z-index: 2;
`;

export const PaymentTitle = styled.div`
  color: #36454f;
  font-family: Work Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 18px;
`;

export const HideHeader = styled.div`
  width: 100%;
  height: 32px;
  position: relative;
  z-index: 100;
  background: #fff;
  border: 1px solid #d0deeb;
  transform: translateY(-32px);
`;

export const FSMask = styled.div`
  transition: all 0.3s ease-in-out;
  opacity: 1;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.1) 10%,

    rgba(0, 0, 0, 0.1) 25%,
    rgba(0, 0, 0, 0.15) 50%,
    rgba(0, 0, 0, 0.1) 75%,
    rgba(0, 0, 0, 0.1) 85%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  position: absolute;
  left: 0;
  top: -35px;
  right: 0;
  bottom: 110px;
  z-index: 9000;
  ${(props) => props?.loaded && `opacity: 1;`}

  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
`;

export const ResetButton = styled.section`
  all: unset;
  position: relative;
  left: 0;
  top: 42%;
  box-sizing: border-box;
  width: 80%;
  border-radius: 5px;
  background: #0d9bff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;

  margin: auto;
  margin-top: 45%;
  @media (max-width: 960px) {
    margin-top: 20%;
  }
  @media (max-width: 600px) {
    margin-top: 20%;
  }
`;

export const FormHolder = styled.div`
  /* transition: all 0.7s ease-in-out;
  opacity: 0;
  max-height: 0px;
  width: 110%;
  transform: translate3D(-5%, -108px, 0);

  ${(props) => props?.loaded && `opacity: 1; max-height: 428px;`}

  @media (max-width: 960px) {
    width: auto;
    transform: translate3D(0, -108px, 0);
    ${(props) => props?.loaded && `opacity: 1; max-height: 424px;`}
  }
  @media (max-width: 800px) {
    width: 110%;
    transform: translate3D(-5%, -108px, 0);
  }

  overflow: hidden;
  z-index: 0 !important;
  iframe {
    z-index: 0 !important;
  }
  @media (max-width: 550px) {
    ${(props) => props?.loaded && `max-height: 474px;`}
  }
  @media (max-width: 400px) {
    transform: translate3D(-5%, -108px, 0);
    ${(props) => props?.loaded && `max-height: 468px;`}
  }

  ${(props) => props?.updated && `transform: translate3D(-2.5%, -50px, 0);`}
  iframe {
    ${(props) => props?.updated && `display: none;`}
  } */
`;

export const PaymentBox = styled.div`
  display: flex;
  flex-direction: column;
  transition: height 0.3s ease-in-out;
  height: 54px;

  width: 100%;

  border-radius: 5px;
  border: 1px solid #d0deeb;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 16px;
  box-sizing: border-box;
  ${(props) => props.checked && `height: 360px;`}
  ${(props) => props.checked && props?.height && `height: ${props?.height}px;`}
  overflow: hidden;

  @media (max-width: 400px) {
    height: 40px;
    ${(props) => props.checked && `height: 340px;`}

    ${(props) =>
      props.checked && props?.height && `height: ${props?.height}px;`}
  }
`;

export const PaymentBanner = styled.div`
  transition: background-color 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 16px 18px;
  ${(props) => props.checked && `background: #F1F5F9;`}

  @media (max-width: 400px) {
    padding: 8px 9px;
    align-items: center;
  }
`;

export const PaymentForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 16px 18px;
  gap: 8px;
`;

export const PaymentRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 21px;
  width: 100%;
`;

export const PaymentCheckbox = styled.div`
  display: flex;

  label {
    display: flex;
    align-items: center;
    color: #000;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label::before {
    margin: 0;
    margin-right: 8px;
    content: "";
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border-radius: 16px;
    border: 2px solid #d9d9d9;
  }

  /* Style for when the checkbox is checked */
  input[type="checkbox"]:checked + label::before {
    margin: 0;
    margin-right: 8px;
    /* background: #0d9bff; */
    border: 8px solid #0d9bff;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 6px;
    height: 6px;
    border-radius: 16px;

    color: white; /* adjust as needed */
  }
`;

export const PaymentLogos = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  @media (max-width: 400px) {
    scale: 0.75;
  }
`;

export const FlexBox = styled.div`
  display: flex;
  gap: 12px;
  margin-top: ${({ mt }) => `${mt}px`};
  align-items: center;

  color: #fff;
  font-size: 15px;
  line-height: 45px;
  text-transform: capitalize;
`;
export const BenefitsText = styled.p`
  margin: 0;
  color: #fff;
  font-size: 15px;
  line-height: 45px;
  text-transform: capitalize;
`;

export const RightSection = styled.div`
  max-width: 430px;

  @media (max-width: 960px) {
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 100%;
    width: 100%;
  }
`;

export const CheckoutInfoTitle = styled.h1`
  margin: 0;
  color: #36454f;
  text-align: left;
  font-size: 30px;
  font-weight: 600;

  margin-top: 22px;

  @media (max-width: 960px) {
    text-align: left;
  }
`;

export const CheckoutInfoTitle3 = styled.div`
  color: #36454f;
  text-align: center;

  font-size: 22px;

  font-weight: 700;
  line-height: 150%; /* 33px */

  text-transform: uppercase;
  text-align: left;
`;

export const CheckoutInfoText = styled.p`
  margin: 0;
  color: #36454f;
  font-size: 16px;
  text-transform: capitalize;
  max-width: 399px;
  padding-bottom: 27px;
  padding-top: 6px;
  border-bottom: 1px solid #e4e4e4;

  @media (max-width: 960px) {
    max-width: 100%;
  }
`;

export const CheckoutInfoTextRodrigo = styled.p`
  margin: 0;
  color: #36454f;
  font-size: 16px;
  text-transform: capitalize;
  max-width: 399px;

  padding-top: 6px;

  @media (max-width: 960px) {
    max-width: 100%;
  }
`;

export const CheckoutInfoText3 = styled.div`
  color: #8c8c8c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  span {
    color: #8c8c8c;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const UserNumber = styled.p`
  color: #36454f;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  @media (max-width: 420px) {
    width: 100%;
  }
`;

export const AddRemoveSignHolder = styled.div`
  padding: 5px 10px;
  border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #d0deeb;
  cursor: pointer;
  ${(props) => props.disabled && "cursor: not-allowed;"}

  background: #e7eff6;
  color: rgba(0, 0, 0, 0.4);
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  @media (max-width: 420px) {
    padding: 5px 5px;
    font-size: 15px;
    line-height: 10px;
  }
`;

export const UserInput = styled.input`
  color: #36454f;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  max-width: 51px;
  text-align: center;
  border: none;
  background: #fff;
  border: 1px solid #d0deeb;

  @media (max-width: 420px) {
    padding: 2.5px 5px;
    font-size: 15px;
    max-width: 30px;
  }
`;

export const UserInputHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 36px;
  padding-top: 14px;
  padding-bottom: 14px;
  @media (max-width: 420px) {
    gap: 8px;
  }
`;

export const InputHolder = styled.div`
  display: flex;
  border-radius: 2px;
  border: 1px solid #d0deeb;
`;

export const YearPlanHolder = styled.div`
  position: relative;
  margin-top: 35px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid #d0deeb;
  background: #e7eff6;
  gap: 10px;
  padding: 32px;
`;

export const PlanHolder = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d0deeb;
  background: #e7eff6;
`;

export const PlanRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const PlanName = styled.div`
  color: #36454f;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  ${(props) => props.bold && `font-weight: 600;`}
`;
export const PlanValue = styled.div`
  color: #36454f;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  gap: 8px;
  text-align: right;
`;

export const DiscountPlan = styled.p`
  margin: 0;
  color: #36454f;
  font-size: 18px;
  font-weight: 400;
  text-decoration: line-through;
  text-transform: uppercase;
`;

export const Line3 = styled.div`
  width: 100%;
  height: 1px;
  background: #d0deeb;
  margin: 24px 0;
  ${(props) => props.margin && `margin: ${props.margin}px 0;`}
`;

export const YearPlanPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const Price = styled.p`
  margin: 0;
  color: #36454f;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
`;
export const Discount = styled.p`
  margin: 0;
  color: #8d8d8d;
  font-size: 18px;
  font-weight: 600;
  text-decoration: line-through;
  text-transform: uppercase;
`;

export const YearPlanTitle = styled.div`
  margin: 0;
  color: #36454f;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const YearPlanText = styled.div`
  margin: 0;
  color: #36454f;
  font-size: 14px;
  text-transform: capitalize;
  text-align: ${({ end }) => end && "end"};
`;
export const OCRHolder = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 400px;
  justify-content: space-between;

  @media (max-width: 960px) {
    max-width: 100%;
    margin-right: 20px;
  }
`;
export const OCRText = styled.p`
  margin: 0;
  color: #36454f;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.6px;
  text-transform: uppercase;
`;
export const OCRDesc = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  max-width: 225px;
  color: rgb(54, 69, 79);
`;

export const CheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 2px solid #36454f;
  background: #fff;
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  margin-top: 5px;

  cursor: pointer;
  ${(props) => props.disabled && "cursor: not-allowed;"}
`;
export const OCRflex = styled.div`
  display: flex;
  gap: 10px;
`;
export const Border = styled.div`
  margin: 0;
  margin-top: 30px;
  border-bottom: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;
  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const BorderBottom = styled.div`
  margin: 0;
  border-bottom: 1px solid #e4e4e4;
`;

export const Total = styled.p`
  padding-top: 27px;
  margin: 0;
  color: #36454f;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 2.2px;
  text-transform: uppercase;
`;

export const Total3 = styled.div`
  color: #36454f;

  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
`;

export const SubscriptionInfo = styled.div`
  color: #36454f;
  font-family: Work Sans;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 17px; /* 130.769% */
  text-transform: capitalize;
  margin: 20px 0;
`;
export const TotalHolder = styled.div`
  display: flex;
  justify-content: space-between;

  max-width: 400px;

  @media (max-width: 960px) {
    max-width: 100%;
    margin-right: 20px;
  }
`;

export const TotalPadding = styled.div`
  padding-bottom: 25px;
`;
export const Currency = styled.p`
  margin: 0;
  margin-top: 32px;
  padding-bottom: 45px;
  color: #36454f;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.6px;
  text-transform: uppercase;
`;
export const CurrencyValue = styled.span`
  color: #36454f;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
`;

export const WaveHolder = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -5;
`;

export const BannerHolder = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
`;
export const BannerText = styled.div`
  position: absolute;
  background-color: #fb4a38;
  color: #ffff;
  font-weight: 700;
  top: 0px;
  right: 15px;
  font-size: 16px;
  z-index: 10;
`;
