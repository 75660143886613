import React, { useEffect } from "react";
import { useState } from "react";
import RightSideCheckout from "../components/Checkout/RightSide";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import LeftSide from "../components/Checkout/LeftSide";
import LeftSide2 from "../components/Checkout/LeftSide2";
import { productInfo } from "../helpers/PriceCalculator";
import useCheckout from "../Zustand/CheckoutStore";
import useLanguage from "../Zustand/LanguageStore";
const CheckoutWrapper = styled.div`
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  ${(props) => props.mount && "opacity: 1;"}
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: space-around;
  width: 90%;
  max-width: 1444px;
  padding: 0 24px;
  gap: 64px;
  padding-top: 24px;
  @media (max-width: 960px) {
    padding-top: 0;

    flex-direction: column;
    gap: 48px;
    align-items: center;
  }

  @media (max-width: 400px) {
    padding: 0px;
  }
`;
function Checkout() {
  const [refresh, setRefresh] = useState(false);
  const [isStaging, setIsStaging] = useState(false);
  const [step, setStep] = useState(1);
  const [mount, setMount] = useState(false);
  const [langChange, setLangChange] = useState(false);

  const { language } = useLanguage();
  const { RealPath, ProductPath, setPath, setAddOn } = useCheckout();

  useEffect(() => {
    setMount(true);
  }, []);
  useEffect(() => {
    if (mount) {
      setLangChange(true);
    }

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("page");
    console.log("myParam: ", myParam);
    if (myParam) {
      switch (myParam) {
        case "buy-normal":
          setPath("pdf-reader");
          break;
        case "buy-pro":
          setPath("pdf-pro");
          break;
        case "upgrade-pro":
          setPath("pdf-pro-upgrade");
          setAddOn(false);

          break;
        case "maintenance-update":
          setPath("pdf-reader-maintenance");
          setAddOn(false);
          break;
        default:
          setPath("pdf-reader");
      }
    }
  }, [language]);

  useEffect(() => {
    setIsStaging(!window.location.origin.includes("https://pdfreader.com"));
    if (refresh) {
      var scriptTag = document.getElementById("fsc-api");
      if (scriptTag) {
        scriptTag.parentNode.removeChild(scriptTag);
      }
      setRefresh(false);
    }
  }, [refresh]);

  return (
    <CheckoutWrapper mount={mount}>
      {!refresh && (
        <Helmet>
          {isStaging ? (
            <script
              id="fsc-api"
              src="https://sbl.onfastspring.com/sbl/0.9.5/fastspring-builder.min.js"
              type="text/javascript"
              data-access-key="JDYKNM48S3AWVW5RBDMD4G"
              data-storefront="pdfreader.test.onfastspring.com/embedded-pdfreader"
              data-fsc-item-path="pdf-reader"
              data-popup-webhook-received="paymentReceived"
            ></script>
          ) : (
            <script
              id="fsc-api"
              src="https://sbl.onfastspring.com/sbl/0.9.5/fastspring-builder.min.js"
              type="text/javascript"
              data-storefront="pdfreader.onfastspring.com/embedded-pdfreader"
              data-fsc-item-path="pdf-reader"
              data-access-key="JDYKNM48S3AWVW5RBDMD4G"
              data-popup-webhook-received="paymentReceived"
            ></script>
          )}
        </Helmet>
      )}

      <RightSideCheckout step={step} />
      {step === 1 && (
        <LeftSide
          refresh={refresh}
          setRefresh={setRefresh}
          setStep={setStep}
          langChange={langChange}
          setLangChange={setLangChange}
        />
      )}
      {step === 2 && (
        <LeftSide2
          refresh={refresh}
          setRefresh={setRefresh}
          setStep={setStep}
        />
      )}
      {/* <RightSide> </RightSide> */}
    </CheckoutWrapper>
  );
}

export default Checkout;
