import React, { useEffect, useState } from "react";
import {
  Column,
  LeftSide,
  NumberHolder,
  Row,
  Subtitle,
  Title,
  Number,
  NumberArrows,
  Price,
  FeatureRow,
  FeatureColumn,
  Line,
  CheckBox,
  AddOnText,
  AddOnDescription,
  AddOnPrice,
  AddOnRow,
  AddOnColumn,
  Taxes,
  TotalVat,
  TotalPrice,
  PerksRow,
  Perks,
  RightSide,
  RowPrice,
} from "./CheckoutStyle";
import ArrowUp from "./icons/ArrowUp.svg";
import ArrowDown from "./icons/ArrowDown.svg";
import Create from "./icons/CreateConvert.svg";
import Edit from "./icons/EditSource.svg";
import Read from "./icons/ReadFillSign.svg";
import Check from "./icons/Check.svg";
import Headphones from "./icons/Headphones.svg";
import Lock from "./icons/Lock.svg";
import Shield from "./icons/Shield.svg";
import useCheckout from "../../Zustand/CheckoutStore";
import { productInfo } from "../../helpers/PriceCalculator";
import useLanguage from "../../Zustand/LanguageStore";

function RightSideCheckout({ step }) {
  const { setAddOn, AddOn, Amount, setAmount, RealPath } = useCheckout();

  const { language } = useLanguage();

  const [price, setPrice] = useState(productInfo(RealPath, Amount, AddOn));
  const [myTag, setMyTag] = useState("");
  const [product, setProduct] = useState("PDF Reader");

  useEffect(() => {
    if (RealPath.includes("pdf-pro")) {
      setProduct("PDF PRO");
    }
    if (RealPath.includes("pdf-pro-upgrade")) {
      setProduct("PDF PRO Upgrade");
    }
    if (RealPath.includes("pdf-reader-maintenance")) {
      setProduct("Maintenance Reader");
    }
    if (RealPath.includes("pdf-pro-maintenance")) {
      setProduct("Maintenance Pro");
    }
    setMyTag(window?.myTag);
    console.log("My Tag: ", myTag);
  }, [language]);

  useEffect(() => {
    console.log("Language: ", productInfo(RealPath, Amount, AddOn));
    setPrice(productInfo(RealPath, Amount, AddOn));
  }, [AddOn, Amount, language]);

  return (
    <RightSide>
      <Row>
        {product !== "Maintenance Reader" && product !== "Maintenance Pro" && (
          <Column>
            <Title>{product}</Title>

            <Subtitle>
              The all-in-one PDF application for Windows. Perpetual license.
              Includes free updates for 1 year.
            </Subtitle>
          </Column>
        )}
        {product === "Maintenance Reader" && (
          <Column>
            <Title>PDF Reader Maintenance Plan Renewal for 1 Year</Title>

            <Subtitle maintenance={true}>
              <div>Renew to receive all the new features of PDF Writer.</div>
              <div>
                Eligibility: all existing clients whose maintenance plan has
                expired.
              </div>
            </Subtitle>
          </Column>
        )}
        {product === "Maintenance Pro" && (
          <Column>
            <Title>PDF PRO Maintenance Plan Renewal for 1 Year</Title>

            <Subtitle maintenance={true}>
              <div>Renew to receive all the new features of PDF PRO.</div>
              <div>
                Eligibility: all existing clients whose maintenance plan has
                expired.
              </div>
            </Subtitle>
          </Column>
        )}
        <RowPrice>
          <NumberHolder gray={step === 2}>
            <Number
              value={Amount}
              disabled={step === 2}
              onChange={(e) => {
                if (step !== 2)
                  setAmount(e.target.value > 0 ? parseInt(e.target.value) : 1);
              }}
            />
            <NumberArrows>
              <ArrowUp
                onClick={() => {
                  if (step !== 2) setAmount(Amount + 1);
                }}
              />
              <ArrowDown
                onClick={() => {
                  if (step !== 2) setAmount(Amount > 1 ? Amount - 1 : Amount);
                }}
              />
            </NumberArrows>
          </NumberHolder>
          <Price>{price.productPrice}</Price>
        </RowPrice>
      </Row>
      <FeatureColumn>
        <FeatureRow>
          <Create />
          <div>Create & Convert</div>
        </FeatureRow>
        <FeatureRow>
          <Edit />
          <div>Edit & Secure</div>
        </FeatureRow>
        <FeatureRow>
          <Read />
          <div>Read, Fill, & Sign</div>
        </FeatureRow>
      </FeatureColumn>
      <Line />
      {!product.includes("Maintenance") && !RealPath.includes("upgrade") && (
        <>
          <AddOnRow>
            <CheckBox
              gray={step === 2}
              onClick={() => {
                if (step !== 2) setAddOn(!AddOn);
              }}
            >
              {AddOn && <Check />}
            </CheckBox>
            <AddOnColumn>
              <AddOnText>Online File Backup Protection – 1 Year</AddOnText>
              <AddOnDescription>
                Secure your download and guarantee access via a protected link
                for 1 year after your purchase.
              </AddOnDescription>
            </AddOnColumn>
            <AddOnPrice>{price.addOnPrice}</AddOnPrice>
          </AddOnRow>
          <Line />
        </>
      )}
      {/* <Column>
        <Row>
          <Total>
            Total <span>(CAD)</span>
          </Total>
          <AddOnPrice>$54.90</AddOnPrice>
        </Row>
        <Row>
          <Vat>VAT</Vat>
          <AddOnPrice>$5.00</AddOnPrice>
        </Row>
      </Column> */}
      {/* <Line /> */}
      <Row>
        <TotalVat>
          TOTAL {myTag !== "US" && <span>({price?.currency})</span>}
          {(myTag === "US" || myTag === "CA" || myTag === "AU") && (
            <Taxes>*Before taxes</Taxes>
          )}
        </TotalVat>
        <TotalPrice>{price?.totalPriceFormatter}</TotalPrice>
      </Row>
      <Line />
      <PerksRow>
        <Perks>
          <Headphones />
          <div>Dedicated Customer Support</div>
        </Perks>
        <Perks>
          <Lock />
          <div>100% Secure Transaction</div>
        </Perks>
        <Perks>
          <Shield />
          <div>30-Day Money Back Guarantee</div>
        </Perks>
      </PerksRow>
    </RightSide>
  );
}

export default RightSideCheckout;
